/* eslint-disable max-len */

exports.Routes = {
    HOME: 'home',
    LOGOUT: 'logout',
    RESULTS_MANAGEMENT: 'results-management',
    PATIENTS: 'patients',
    PROFILE: 'profile',
    ORDER_MANAGEMENT: 'order-management',
    DASHBOARD: 'dashboard'
};

exports.ORDER_STATUS = {
    PLACED: 'placed',
    DISPATCHED: 'dispatched',
    DELIVERED: 'delivered',
    CANCELED: 'canceled',
    INVALID: 'invalid',
    RECEIVED_BY_LAB: 'received_by_lab',
    REVIEW: 'review',
    COMPLETED: 'completed',
    ACTIVATED: 'activated',
    DISPATCHED_TO_LAB: 'dispatched_to_lab',
    SCHEDULING_PHLEBOTOMY: 'scheduling_phlebotomy',
    PHLEBOTOMY_SCHEDULED: 'phlebotomy_scheduled',
    PHLEBOTOMY_DONE: 'phlebotomy_done'
};

exports.SubscriptionStatus = {
    UNSUBSCRIBED: 'unsubscribed',
    SUBSCRIBED: 'subscribed',
    TRIAL: 'trial',
    NO_SUBSCRIPTION_NEEDED: 'no_subscription_needed'
};

exports.Roles = {
    PATIENT: 'patient',
    ADMIN: 'admin',
    DOCTOR: 'doctor',
    TRIAL: 'trial',
    DEMO: 'demo',
    VIEW: 'view'
};

exports.OrganisationPermissions = {
    WEARABLES_ACCESS: 'ypl.app.wearables',
    SUBSCRIPTIONS_ENABLED: 'ypl.subscriptions.enabled',
    SUBSCRIPTIONS_DISABLED: 'ypl.subscriptions.disabled',
    MENSTRUATION_TRACKER: 'ypl.app.menstruation.tracker'
};

exports.SubscriptionEntitlementName = {
    GENERAL_ACCESS: 'sub_general_access_membership',
    BLOOD_KIT_ACCESS: 'sub_blood_kit_access'
};

exports.MAX_PERIOD_LENGTH = 10;
exports.MIN_DISTANCE_BETWEEN_PERIOD_START_DATES = 12;
exports.MAX_CYCLE_LENGTH = 40;
exports.MAX_CYCLE_WITH_DELAY_LENGTH = 50;

exports.AWSImageValues = {
    BODY_WEIGHT_WORKOUT: { label: 'body_exercises', length: 6 },
    CYCLING: { label: 'cycling', length: 8 },
    HOME_WORKOUT: { label: 'home_workout', length: 8 },
    RUNNING: { label: 'running', length: 8 },
    SWIMMING: { label: 'swimming', length: 8 },
    WALKING: { label: 'walking', length: 8 },
    ACTIVITIES: { label: 'activities', length: 14 },
    BODY: { label: 'body', length: 10 },
    SLEEP: { label: 'sleep', length: 7 }
};

exports.AWSImageURL = 'https://ypl-assets-dev.s3.us-west-2.amazonaws.com';

exports.healthcareQuestionnaireVariables = {
    foodIntolerance: {
        id: 'foodIntolerance',
        title: 'Are there certain foods that you do not eat or that you have an intolerance to?',
        description: 'Select the options that best describes your situation',
        type: 'checkbox',
        required: true,
        default: ['none'],
        options: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'lactose',
                label: 'Lactose'
            },
            {
                value: 'gluten',
                label: 'Gluten'
            },
            {
                value: 'sulfites',
                label: 'Sulfites'
            },
            {
                value: 'salicylates',
                label: 'Salicylates'
            },
            {
                value: 'caffeine',
                label: 'Caffeine'
            },
            {
                value: 'histamine',
                label: 'Histamine'
            },
            {
                value: 'other',
                label: 'Other',
                userInput: true
            }
        ]
    },
    specialDiet: {
        id: 'specialDiet',
        title: 'Do you follow a special dietary or fasting plan prescribed for you, recommended by a medical provider?',
        description: 'Select the options that best describes your situation',
        type: 'checkbox',
        required: true,
        default: ['none'],
        options: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'low_cholesterol',
                label: 'Low cholesterol'
            },
            {
                value: 'vegetarian',
                label: 'Vegetarian'
            },
            {
                value: 'vegan',
                label: 'Vegan'
            },
            {
                value: 'fasting',
                label: 'Fasting'
            },
            {
                value: 'other',
                label: 'Other',
                userInput: true
            }
        ]
    },
    chosenDiet: {
        id: 'chosenDiet',
        title: 'Have you adopted a particular diet, eating pattern, or training meal plan for reasons such as personal health goals, ethical or religious beliefs?',
        description: 'Select the options that best describes your situation',
        type: 'checkbox',
        required: true,
        default: ['none'],
        options: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'paleo',
                label: 'Paleo'
            },
            {
                value: 'weight_watchers',
                label: 'Weight Watchers'
            },
            {
                value: 'keto',
                label: 'Keto'
            },
            {
                value: 'kosher',
                label: 'Kosher'
            },
            {
                value: 'marathon_eating_plan',
                label: 'Marathon training eating plan'
            },
            {
                value: 'bodybuilder_eating_plan',
                label: 'Bodybuilder training eating plan'
            },
            {
                value: 'powerlifter_eating_plan',
                label: 'Powerlifter training eating plan'
            },
            {
                value: 'other',
                label: 'Other',
                userInput: true
            }
        ]
    },
    nutraceuticals: {
        id: 'nutraceuticals',
        required: true,
        title: 'Please list all nutraceuticals you are currently taking or have taken/used in the past year. Please also list the details about the dose and frequency.',
        description: 'Vitamins, minerals, herbals, supplements, ergogenic aids, performance enhancers, protein powders, meal replacements, or other',
        name: 'Nutraceuticals input',
        type: 'text',
        placeholder: 'Enter your answer here',
        default: ''
    },
    medication: {
        id: 'medication',
        required: true,
        title: 'Please list all the medication you are currently taking or have taken/used in the past year. Please also list the details about the dose and frequency.',
        name: 'Medication input',
        type: 'text',
        placeholder: 'Enter your answer here',
        default: ''
    },
    beveragesIntake: {
        id: 'beveragesIntake',
        required: true,
        title: 'What beverages do you typically drink within a week and how much?',
        name: 'Beverages intake',
        description: '',
        type: 'text',
        placeholder: 'Enter your answer here',
        default: ''
    },
    dailyWaterIntake: {
        id: 'dailyWaterIntake',
        type: 'radio',
        required: true,
        default: 'between_1l_2l',
        title: 'How much water do you drink daily?',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'under_1l',
                label: 'Under 1L per day'
            },
            {
                value: 'between_1l_2l',
                label: 'Between 1L and 2L per day'
            },
            {
                value: 'between_2l_3l',
                label: 'Between 2L and 3L per day'
            },
            {
                value: 'over_3l',
                label: 'Over 3L per day'
            }
        ]
    },
    healthyScaleScore: {
        id: 'healthyScaleScore',
        type: 'rating',
        default: 5,
        required: true,
        title: 'How healthy do you consider yourself on a scale of 1 to 10?',
        description: '1 being the least healthy and 10 being the most healthy',
        max: 10
    },
    healthCheckupPeriod: {
        id: 'healthCheckupPeriod',
        type: 'radio',
        required: true,
        default: '3months',
        title: 'How often do you get a health checkup?',
        description: 'Select the option that best describes you',
        options: [
            {
                value: '3months',
                label: 'Every 3 months'
            },
            {
                value: '6months',
                label: 'Every 6 months'
            },
            {
                value: 'yearly',
                label: 'Every year'
            },
            {
                value: 'when_needed',
                label: 'Only when needed'
            },
            {
                value: 'never',
                label: 'Never'
            }
        ]
    },
    overallPhysicalHealthAssessment: {
        id: 'overallPhysicalHealthAssessment',
        type: 'radio',
        required: true,
        default: 'good',
        title: 'What do you say about your overall physical health?',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'good',
                label: 'Having good physical health'
            },
            {
                value: 'moderately_impaired',
                label: 'Moderately physically impaired'
            },
            {
                value: 'severely_impaired',
                label: 'Severely physically impaired'
            },
            {
                value: 'disabled',
                label: 'Physically disabled'
            }
        ]
    },
    overallMentalHealthAssessment: {
        id: 'overallMentalHealthAssessment',
        type: 'radio',
        required: true,
        default: 'good',
        title: 'What do you say about your overall mental health?',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'good',
                label: 'Having good mental health'
            },
            {
                value: 'moderately_impaired',
                label: 'Moderately mentally impaired'
            },
            {
                value: 'severely_impaired',
                label: 'Severely mentally impaired'
            },
            {
                value: 'disabled',
                label: 'mentally disabled'
            }
        ]
    },
    chronicDiseases: {
        id: 'chronicDiseases',
        type: 'radio',
        default: 'no',
        required: true,
        title: 'Do you have any chronic diseases (eg. asthma, heart disease, diabetes, arthritis)? If yes, please specify which one(s).',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'no',
                label: 'No'
            },
            {
                value: 'yes',
                label: 'Yes',
                userInput: true
            }
        ]
    },
    allergies: {
        id: 'allergies',
        type: 'radio',
        default: 'no',
        required: true,
        title: 'Do you have any allergies? If yes, please specify which one(s).',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'no',
                label: 'No'
            },
            {
                value: 'yes',
                label: 'Yes',
                userInput: true
            }
        ]
    },
    hereditaryDiseases: {
        id: 'hereditaryDiseases',
        title: 'Do you have any hereditary conditions/diseases?',
        description: 'Select the option that best describes you',
        type: 'checkbox',
        required: true,
        default: ['none'],
        options: [
            {
                value: 'none',
                label: 'None'
            },
            {
                value: 'diabetes',
                label: 'Diabetes'
            },
            {
                value: 'hbp',
                label: 'High Blood Pressure'
            },
            {
                value: 'hemophilia',
                label: 'Hemophilia'
            },
            {
                value: 'thalassemia',
                label: 'Thalassemia'
            },
            {
                value: 'other',
                label: 'Other',
                userInput: true
            }
        ]
    },
    injuryIllness: {
        id: 'injuryIllness',
        type: 'radio',
        default: 'no',
        required: true,
        title: 'Do you have any history of injury or illness? If yes, please specify which one(s).',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'no',
                label: 'No'
            },
            {
                value: 'yes',
                label: 'Yes',
                userInput: true
            }
        ]
    },
    currentlyPregnant: {
        id: 'currentlyPregnant',
        type: 'radio',
        default: 'no',
        gender: 'F',
        required: true,
        title: 'Are you currently pregnant? If yes please specify the number of months.',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'no',
                label: 'No'
            },
            {
                value: 'yes',
                label: 'Yes',
                userInput: true
            }
        ]
    },
    onMenopause: {
        id: 'onMenopause',
        type: 'radio',
        default: 'not-menopausal',
        gender: 'F',
        required: true,
        title: 'What is your current menopausal status?',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'not-menopausal',
                label: 'Not menopausal'
            },
            {
                value: 'pre-menopause',
                label: 'Pre-menopause'
            },
            {
                value: 'perimenopausal',
                label: 'Perimenopausal'
            },
            {
                value: 'postmenopausal',
                label: 'Postmenopausal'
            },
            {
                value: 'surgically-menopause',
                label: 'Surgically menopausal'
            }
        ]
    },
    hrtTreatment: {
        id: 'hrtTreatment',
        type: 'radio',
        default: 'no',
        gender: 'F',
        required: true,
        title: 'Are you taking any HRT (Hormone Replacement Therapy) treatment?',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'no',
                label: 'No'
            },
            {
                value: 'yes',
                label: 'Yes'
            }
        ]
    },
    contraceptionUsage: {
        id: 'contraceptionUsage',
        type: 'radio',
        default: 'no',
        gender: 'F',
        required: true,
        title: 'Are you taking/using any contraception? If yes, please specify which one(s).',
        description: 'Select the option that best describes you',
        options: [
            {
                value: 'no',
                label: 'No'
            },
            {
                value: 'yes',
                label: 'Yes',
                userInput: true
            }
        ]
    },
    drugsAlcoholHabit: {
        id: 'drugsAlcoholHabit',
        title: 'Are you currently/or have been previously habitual to drugs and/or alcohol?',
        description: 'Select the option that best describes you',
        type: 'radio',
        default: 'no',
        options: [
            {
                value: 'no',
                label: 'I am not habituated to either'
            },
            {
                value: 'drugs',
                label: 'I am habituated to drugs'
            },
            {
                value: 'alcohol',
                label: 'I am habituated to alcohol'
            },
            {
                value: 'both',
                label: 'I am habituated to both'
            }
        ]
    },
    daysPerWeekExercise: {
        id: 'daysPerWeekExercise',
        title: 'How many days per week do you exercise? (gym or outdoor activities)',
        description: 'Select the option that best describes you',
        type: 'radio',
        default: '0',
        options: [
            {
                value: '0',
                label: 'I do not exercise'
            },
            {
                value: '1',
                label: '1 day/week'
            },
            {
                value: '2-3',
                label: '2-3 days/week'
            },
            {
                value: '3-5',
                label: '3-5 days/week'
            },
            {
                value: 'more-than-5',
                label: 'More than 5 days/week'
            }
        ]
    },
    trainingOutdoorActivities: {
        id: 'trainingOutdoorActivities',
        required: true,
        title: 'What type and intensity of training or outdoor activities do you do?',
        name: 'Outdoor / Training Activities',
        description: 'Write the type of training or outdoor activities you usually do',
        type: 'text',
        placeholder: 'Enter your answer here',
        default: ''
    }
};

exports.wearableTypeDetails = {
    daily: {
        itemsIncluded: ['heart rate info, oxygen'],
        label: 'Daily'
    },
    body: {
        itemsIncluded: ['glucose info'],
        label: 'Body'
    },
    sleep: {
        itemsIncluded: ['sleep duration, quality, REM details'],
        label: 'Sleep'
    },
    menstruation: {
        itemsIncluded: ['period length', 'cycle length'],
        label: 'Menstruation'
    }
};

exports.wearableDataInfoIcons = {
    activity_data: {
        icon: require('../assets/svg/icons/wear-metrics/weightlifting.svg'),
        id: 'activity_data'
    },
    general_sleep_data: {
        icon: require('../assets/svg/icons/wear-metrics/sleep.svg'),
        id: 'general_sleep_data'
    },
    heart_rate_data: {
        icon: require('../assets/svg/icons/wear-metrics/heart-rate.svg'),
        id: 'heart_rate_data'
    },
    oxygen_data: {
        icon: require('../assets/svg/icons/wear-metrics/O2.svg'),
        id: 'oxygen_data'
    },
    scores: {
        icon: require('../assets/svg/icons/wear-metrics/weightlifting.svg'),
        id: 'scores'
    },
    glucose_data: {
        icon: require('../assets/svg/icons/wear-metrics/blood-sugar-test.svg'),
        id: 'glucose_data'
    },
    menstruation_data: {
        id: 'menstruation_data',
        icon: require('../assets/svg/icons/wear-metrics/period-cycle.svg')
    },
    avg_hr_bpm: {
        id: 'avg_hr_bpm',
        icon: require('../assets/svg/icons/wear-metrics/avg_heart_rate.svg')
    },
    duration_asleep_state_seconds: {
        id: 'duration_asleep_state_seconds',
        icon: require('../assets/svg/icons/wear-metrics/sleep_duration.svg')
    },
    'activity.avg_hr_bpm': {
        icon: require('../assets/svg/icons/wear-metrics/avg_heart_rate.svg'),
        id: 'activity.avg_hr_bpm'
    },
    avg_saturation_percentage: {
        icon: require('../assets/svg/icons/wear-metrics/O2.svg'),
        id: 'avg_saturation_percentage'
    },
    day_avg_blood_glucose_mg_per_dL: {
        icon: require('../assets/svg/icons/wear-metrics/glucose.svg'),
        id: 'day_avg_blood_glucose_mg_per_dL'
    },
    num_REM_events: {
        icon: require('../assets/svg/icons/wear-metrics/rem.svg'),
        id: 'num_REM_events'
    },
    sleep_efficiency: {
        icon: require('../assets/svg/icons/wear-metrics/sleep_efficiency.svg'),
        id: 'sleep_efficiency'
    },
    movement_data_pace: {
        icon: require('../assets/svg/icons/wear-metrics/pace.svg'),
        id: 'movement_data_pace'
    },
    active_durations_data: {
        icon: require('../assets/svg/icons/wear-metrics/weightlifting.svg'),
        id: 'active_durations_data'
    },
    inactivity_seconds: {
        icon: require('../assets/svg/icons/wear-metrics/sleep.svg'),
        id: 'inactivity_seconds'
    },
    activity_seconds: {
        icon: require('../assets/svg/icons/wear-metrics/weightlifting.svg'),
        id: 'activity_seconds'
    },
    calories_data: {
        icon: require('../assets/svg/icons/wear-metrics/calories.svg'),
        id: 'calories_data'
    },
    net_activity_calories: {
        icon: require('../assets/svg/icons/wear-metrics/calories.svg'),
        id: 'net_activity_calories'
    },
    net_intake_calories: {
        icon: require('../assets/svg/icons/wear-metrics/calories.svg'),
        id: 'net_intake_calories'
    },
    total_burned_calories: {
        icon: require('../assets/svg/icons/wear-metrics/calories.svg'),
        id: 'total_burned_calories'
    },
    distance_meters: {
        icon: require('../assets/svg/icons/wear-metrics/distance.svg'),
        id: 'distance_meters'
    },
    steps: {
        icon: require('../assets/svg/icons/wear-metrics/steps.svg'),
        id: 'steps'
    },
    swimming: {
        icon: require('../assets/svg/icons/wear-metrics/swimming.svg'),
        id: 'swimming'
    },
    avg_pace_minutes_per_kilometer: {
        icon: require('../assets/svg/icons/wear-metrics/pace.svg'),
        id: 'avg_pace_minutes_per_kilometer'
    },
    max_pace_minutes_per_kilometer: {
        icon: require('../assets/svg/icons/wear-metrics/pace.svg'),
        id: 'max_pace_minutes_per_kilometer'
    }
};

exports.wearableMetricCategoryDetails = {
    activity_data: {
        icon: this.wearableDataInfoIcons.activity_data.icon,
        id: 'activity_data',
        label: 'Activity',
        tips: [
            'Aim for at least 150 minutes of moderate-intensity aerobic activity or 75 minutes of vigorous-intensity aerobic activity each week',
            'Incorporate strength training exercises targeting all major muscle groups at least two days per week',
            'Take short breaks throughout the day to stretch and move, especially if you have a sedentary job',
            "Find activities you enjoy, making it more likely you'll stick to your routine",
            'Listen to your body and adjust your workouts as needed to avoid injury or burnout',
            'Remember to warm up before exercise and cool down afterwards to promote flexibility and prevent injury'
        ],
        hideFromSummary: true,
        healthMetric: false
    },
    general_sleep_data: {
        icon: this.wearableDataInfoIcons.general_sleep_data.icon,
        id: 'general_sleep_data',
        label: 'General Sleep',
        tips: [
            'Avoid large meals, caffeine, and alcohol before bedtime',
            'Exercise. Being physically active during the day can help you fall asleep more easily at night.',
            'Make your bedroom quiet and relaxing. Keep the room at a comfortable, cool temperature',
            'Follow the 321 Rule. No food for 3 hours before bed, no water for 2 hour before bed, and no screen time for 1 hour before bed'
        ],
        healthMetric: true
    },
    heart_rate_data: {
        icon: this.wearableDataInfoIcons.heart_rate_data.icon,
        id: 'heart_rate_data',
        label: 'Heart Rate',
        tips: ['Avoid caffeine and nicotine', 'Stay hydrated', 'Get enough sleep', 'Exercise regularly', 'Eat a healthy diet'],
        healthMetric: true
    },
    oxygen_data: {
        icon: this.wearableDataInfoIcons.oxygen_data.icon,
        id: 'oxygen_data',
        label: 'Oxygen',
        tips: [
            'Do simple breathing exercises like pursed-lip breathing and deep belly breathing',
            'Breathing in the fresh air: Opening your windows or going outside for a walk',
            'Quit smoking: Only two weeks after you quit smoking, your circulation will likely improve significantly.'
        ],
        healthMetric: true
    },
    glucose_data: {
        icon: this.wearableDataInfoIcons.glucose_data.icon,
        id: 'glucose_data',
        label: 'Glucose',
        tips: [
            "Eat at regular times, and don't skip meals",
            'Drink water instead of soda and for a sweet treat, choose fruit',
            'Choose foods lower in saturated fat, trans fat, sugar, and salt.'
        ],
        healthMetric: true
    },
    menstruation_data: {
        icon: this.wearableDataInfoIcons.menstruation_data.icon,
        id: 'menstruation_data',
        label: 'Menstruation',
        tips: [
            'Limit Sugar, Alcohol, and Caffeine',
            'Increase Iron Intake',
            'Eat Magnesium Rich Foods (including spinach, pumpkin seeds, bananas, almonds, and dark chocolate)'
        ],
        healthMetric: true
    }
};

exports.bloodCategoryDetails = {
    liverFunction: {
        title: 'Liver Function',
        description:
            'These are groups of blood tests that provide information about the functional capacity of the liver.\nThe liver processes blood and breaks down balances, creates the nutrients (carbohydrates, fat, and proteins), and also metabolizes drugs into forms that are easier to use for the rest of the body.\nThe liver also:\n- Synthesise proteins for the blood\n- Synthesise the cholesterol and special proteins to help carry fats through the body\n- Converts excess glucose into glycogen for storage (glycogen can later be converted back to glucose for energy)\n- Regulates blood levels of amino acids, which form the building blocks of proteins\n- Process hemoglobin for use of its iron content (the liver stores iron)\n- Converts ammonia to urea (urea is an end product of protein metabolism and is excreted in the urine)',
        icon: require('../assets/svg/icons/blood-categories/liver.svg'),
        id: 'liverFunction',
        tips: ['Exercise, eat right, and maintain a healthy weight ', 'Limit alcohol consumption', 'Avoid the use of illicit drugs']
    },
    biochemistry: {
        title: 'Biochemistry',
        description:
            'Are a group of blood tests used for the evaluation of the state of important organs of the human body as the liver, kidneys, and heart.',
        icon: require('../assets/svg/icons/blood-categories/biochemistry.svg'),
        id: 'biochemistry',
        tips: [
            'Exercise three to five times per week',
            'Eat right',
            'Maintain a healthy weight',
            'Limit alcohol consumption',
            'Quit smoking if you smoke'
        ]
    },
    lipids: {
        title: 'Lipids',
        description:
            'A group of blood tests that measures the amount of certain fat molecules is called lipids. The group includes three measurements of your cholesterol levels and a measurement of your triglycerides. With these parameters (plus a few others such as smoking, alcohol intake, obesity, etc) we can evaluate first the risk of cardiovascular diseases like heart disease, and stroke and second the type of person’s lifestyle (athletic, non-athletic, etc)',
        icon: require('../assets/svg/icons/blood-categories/cholesterol.svg'),
        id: 'lipids',
        tips: [
            'Exercise on a regular basis ',
            'Maintain a healthy weight.',
            'Consume Omega-3 fatty acids',
            'Limit alcohol consumption',
            'Quit smoking if you smoke'
        ]
    },
    vitamins: {
        title: 'Vitamins',
        description:
            'A group of organic molecules that are essential for normal growth and metabolism and are essential in small quantities because they cannot be made by our organism.',
        icon: require('../assets/svg/icons/blood-categories/vitamins.svg'),
        id: 'vitamins',
        tips: [
            'Even if you eat healthy, you might still need a vitamin supplement.',
            'If you take vitamins suplements, check with your doctor if everything you take is good for you.',
            "Don't take your supplements any time of the day. Check with your doctor about the best time to take them."
        ]
    },
    kidneyFunction: {
        title: 'Kidney Function',
        description:
            'A group of blood and/or urine tests in which we are checking for amounts of certain substances released by the kidneys. By that, we can evaluate the functional capacity of the kidneys and important parameters of the human body such as hydration/dehydration, metabolism, respiratory and heart condition, etc.',
        icon: require('../assets/svg/icons/blood-categories/kidneys.svg'),
        id: 'kidneyFunction',
        tips: ['Drink plenty of water', 'Exercise three to five times a week', 'Eat low in sugar and processed foods']
    },
    hormones: {
        title: 'Hormones',
        description:
            'Are important organic molecules that act like messengers in the body. After being made in one part of the body they travel through the blood to organs. They work mainly slowly and affect many different processes, including: Growth, Development, and Metabolism.',
        icon: require('../assets/svg/icons/blood-categories/hormones.svg'),
        id: 'hormones',
        tips: [
            'Get enough protein. Proteins provide amino acids that your body cannot make on its own and are needed to produce peptide hormones',
            'Reduce stress',
            'Get enough sleep'
        ]
    },
    immunology: {
        title: 'Immunology',
        description:
            'Is a group of blood tests that employ antigens (foreign proteins) and antibodies (immunoglobulins) to detect abnormalities in the immune system.',
        icon: require('../assets/svg/icons/blood-categories/immunity-protection.svg'),
        id: 'immunology',
        tips: [
            'Eat a healthy diet and eat well',
            'Limit alcohol consumption',
            'Avoid smoking',
            'Maintaining regular physical activity will helps you feel better, sleep better, and reduce anxiety'
        ]
    },
    thyroidFunction: {
        title: 'Thyroid Function',
        description:
            'Blood tests are used to check whether your thyroid is working normally. The thyroid gland is responsible for regulating many processes, such as metabolism, energy generation, and mood.',
        icon: require('../assets/svg/icons/blood-categories/thyroid.svg'),
        id: 'thyroidFunction',
        tips: ['Reduce your stress', 'Exercise three to five times a week', 'Eat low in sugar and processed foods']
    },
    fullBloodCount: {
        title: 'Full Blood Count',
        description:
            'It is a blood test used to evaluate your overall health and detect a wide range of conditions, including dehydration, anemia, infection, and leukemia.\nA complete blood count test measures several components of your blood, including:\n- Red blood cells (cells that carry oxygen)\n- White blood cells, (cells fight infection)\n- Hemoglobin, the oxygen-carrying protein in red blood cells\n- Platelets, which help with blood clotting)',
        icon: require('../assets/svg/icons/blood-categories/blood.svg'),
        id: 'fullBloodCount',
        tips: [
            'Exercise three to five times per week',
            'Eat right',
            'Maintain a healthy weight',
            'Limit alcohol consumption',
            'Quit smoking if you smoke'
        ]
    },
    boneScreen: {
        title: 'Bone Screen',
        description:
            'Is a group of blood and non-blood tests that are used to diagnose or assess your bone health status. We can evaluate bone density and the risk of osteoporosis, a condition that weakens bones and makes them more likely to break.',
        icon: require('../assets/svg/icons/blood-categories/bone.svg'),
        id: 'boneScreen',
        tips: [
            'Pay attention to your Vitamin D and Calcium levels',
            'Include physical activity in your daily routine.',
            'Quite smoking if you smoke.'
        ]
    },
    markers: {
        title: 'Markers',
        description: 'Are a group of markers that are used to monitor certain types of cancers or to assess the effectiveness of cancer treatment',
        icon: require('../assets/svg/icons/blood-categories/cancer-cell.svg'),
        id: 'markers',
        tips: []
    },
    serology: {
        title: 'Serology',
        description:
            'Involves the study and testing of blood serum to detect the presence of antibodies or antigens, commonly used for diagnosing infections, immune disorders, and other medical conditions.',
        icon: require('../assets/svg/icons/blood-categories/more.svg'),
        id: 'serology',
        tips: [
            'Get regular vaccinations',
            'Practice good hygiene',
            'Avoid contact with sick individuals',
            'Eat a balanced diet rich in vitamins and minerals',
            'Regular health check-ups'
        ]
    },
    other: {
        title: 'Other',
        description: '',
        icon: require('../assets/svg/icons/blood-categories/more.svg'),
        id: 'other',
        tips: []
    },
    default: {
        title: 'Other',
        description: '',
        icon: require('../assets/svg/icons/blood-categories/more.svg'),
        id: 'other',
        tips: []
    }
};

const green = '#5ed078';
const yellow = '#fec13f';
const red = '#fd3811';
const blue = '#007bff';

const greenColor = {
    icon: 'green',
    color: green,
    lightColor: 'rgba(94, 208, 120, 0.16)'
};

const yellowColor = {
    icon: 'yellow',
    color: yellow
};

const redColor = {
    icon: 'red',
    color: red,
    lightColor: 'rgba(253, 56, 17, 0.1)'
};

const blueColor = {
    icon: 'blue',
    color: blue
};

const darkGreyColor = {
    icon: 'grey',
    color: '#c2c2c',
    undefined: true
};

const blackGreyColor = {
    icon: 'grey',
    color: '#2d3443',
    undefined: false
};

exports.greenColor = greenColor;
exports.yellowColor = yellowColor;
exports.redColor = redColor;
exports.blackGreyColor = blackGreyColor;
exports.blueColor = blueColor;
exports.darkGreyColor = darkGreyColor;

exports.greyColor = {
    icon: 'grey',
    color: '#757575',
    undefined: true
};

exports.primaryColor = {
    color: '#8A459A'
};

exports.ABNORMAL_FLAGS = {
    NAF: greenColor, // added by me, custom for no abnormal flags
    A: redColor,
    '>': redColor,
    H: redColor,
    HH: redColor,
    AC: redColor,
    '<': redColor,
    L: redColor,
    LL: redColor,
    B: greenColor,
    ACC: yellowColor,
    TOX: yellowColor,
    DET: yellowColor,
    IND: darkGreyColor,
    I: yellowColor,
    MS: yellowColor,
    NEG: greenColor,
    null: darkGreyColor,
    NR: greenColor,
    N: greenColor,
    ND: greenColor,
    POS: redColor,
    QCF: darkGreyColor,
    RR: yellowColor,
    R: yellowColor,
    D: yellowColor,
    U: yellowColor,
    S: yellowColor,
    AA: redColor,
    VS: yellowColor,
    WR: yellowColor,
    W: yellowColor
};

exports.ABNORMAL_FLAGS_TAGGED = {
    A: 'bad',
    '>': 'bad',
    H: 'bad',
    HH: 'bad',
    AC: 'bad',
    '<': 'bad',
    L: 'bad',
    LL: 'bad',
    B: 'good',
    TOX: 'bad',
    DET: 'bad',
    IND: 'bad',
    I: 'bad',
    MS: 'bad',
    NEG: 'good',
    null: 'undefined',
    NR: 'good',
    N: 'good',
    ND: 'good',
    POS: 'bad',
    QCF: 'bad',
    RR: 'bad',
    R: 'bad',
    D: 'bad',
    U: 'bad',
    S: 'bad',
    AA: 'bad',
    VS: 'bad',
    WR: 'bad',
    W: 'bad'
};

exports.UNIT_SYSTEMS = {
    imperial: {
        name: 'Imperial',
        id: 'imperial',
        heightUnitMain: 'in',
        heightUnitSecondary: 'ft',
        weightUnit: 'lbs',
        countries: ['LR', 'MM', 'US']
    },
    metric: {
        name: 'Metric',
        id: 'metric',
        heightUnitMain: 'cm',
        weightUnit: 'kg'
    }
};

exports.rawLocalTest = require('./rawLocalResult.json');

exports.BUTTONS = {
    assignQuiz: 'Assign new quizzes',
    assignResult: 'Assign result to me',
    back: 'Back',
    connectWearables: 'Connect wearable',
    finish: 'Finish',
    generate: 'Generate',
    hide: 'Hide',
    login: 'LOGIN',
    logout: 'Logout',
    next: 'Next',
    manage: 'Manage',
    orderManagement: 'Order Management',
    pickSubscription: 'Pick subscription',
    previous: 'Previous',
    redo: 'Redo',
    regenerate: 'Regenerate',
    review: 'Review',
    reviewResult: 'Review result',
    save: 'Save',
    seeAllKits: 'See all kits',
    seeResult: 'See result',
    show: 'Show',
    store: 'Store',
    unassignResult: 'Unassign result',
    update: 'Update',
    userManagement: 'User Management'
};

exports.GENERAL = {
    dr: 'Dr.',
    doctor: 'Doctor:',
    dobColumn: 'DOB',
    genderColumn: 'Gender',
    heightColumn: 'Height',
    weightColumn: 'Weight',
    nameColumn: 'Name',
    notSet: 'Not Set',
    nothingMentioned: 'Nothing mentioned',
    readOnly: 'You are in read-only mode.',
    showDefaultSetting: 'Show default settings',
    showLegend: 'Show legend',
    you: 'YOU',
    welcome: 'Welcome'
};

exports.STATUS_TYPES = {
    danger: 'danger',
    error: 'error',
    success: 'success',
    warning: 'warning',
    processing: 'processing',
    ready: 'ready',
    safe: 'safe',
    pending: 'pending'
};

exports.BLOOD_HEALTH_INSIGHTS = {
    headline: 'Blood health insights',
    insightsUnavailableMessage:
        'Blood health summary not available. You have to have at least one reviewed blood result in order to be able to see any blood insights.'
};

exports.DASHBOARD = {
    headline: 'Metrics dashboard'
};

exports.DOCTOR_ACTIONS = {
    reviewClosureMessage: 'You cannot change the review for this result it has been already closed',
    collapseActions: 'Collapse Actions',
    expandActions: 'Expand Actions',
    commentsLabel: 'Comments for the result',
    resultUpdatedSuccessMessage: 'Result updated successfully',
    resultUpdateErrorMessage: 'Result not updated due to an error',
    resultClosedSuccessMessage: 'Result updated and closed successfully',
    orderNotClosedErrorMessage: 'Order not closed successfully'
};

exports.HEALTHCARE_QUESTIONNAIRE = {
    headline: 'Lola Health and Nutrition Questionnaire',
    showAllQuestions: 'Show all questions'
};

exports.MENSTRUATION = {
    date: {
        selectShort: 'Select the date for more info',
        selectMedium: 'Please select a date in order to start adding or updating info',
        selectLong: 'There is no data about your last period.\nPlease add at least one start date for your latest period.',
        selectedDate: 'Selected - '
    },
    flow: {
        flow: 'Flow:',
        info: 'Flow info / On period',
        lightFlow: 'Light flow',
        mediumFlow: 'Medium flow',
        heavyFlow: 'Heavy flow'
    },
    period: {
        adjustmentInstructionsLong: 'In order to adjust this, you have to uncheck the period end date checkbox first',
        adjustmentInstructionsShort: 'Please uncheck the last end of the period first.',
        adjustmentWarning:
            'Too close to the last start of period. If you want to adjust the start of the period, please initially uncheck the already started one',
        forecastedPeriod: 'Forecasted Period',
        forecastMessage: 'On period - forecast',
        endMessage: 'Period ended',
        endErrorMessage: 'You cannot end a period if it has started in the same day',
        startMessage: 'Period started',
        startErrorMessage: 'You cannot start a period if it has already ended in the same day',
        manuallySetMessage: 'On period - manually set'
    },
    notes: {
        dailyNote: 'Daily note:',
        noteMessage: 'There is a note',
        noteOnDayMessage: 'There is a note on this day'
    },
    fertile: 'Fertile',
    ovulating: 'Ovulating'
};

exports.ORDER_MANAGEMENT = {
    headline: 'Order Management',
    noOrders: 'You have no orders to look at yet'
};

exports.ORDERS_TABLE = {
    searchByIdPlaceholderText: 'Search by id, name',
    filterByPatientPlaceholderText: 'Filter by patient',
    kitOrderIdLabel: 'Kit Order ID',
    kitTypeLabel: 'Kit Type',
    statusLabel: 'Status:',
    placedDateLabel: 'Placed Date:',
    labResultDayLabel: 'Lab Result Date:',
    notInLabStatusMessage: 'Not in lab',
    finishedOnLabel: 'Finished on:',
    notFinishedStatusMessage: 'Not finished',
    idLabel: 'ID:',
    nameLabel: 'Name:',
    genderLabel: 'Gender:',
    ageLabel: 'Age:',
    filterOptions: {
        all: {
            id: 'all',
            label: 'All'
        },
        withResults: {
            id: 'with-results',
            label: 'With Results'
        },
        withoutResults: {
            id: 'without-results',
            label: 'Without Results'
        }
    }
};

exports.PROFILE_TABS = {
    myProfile: {
        id: 'me',
        label: 'My Profile'
    },
    tenant: {
        id: 'tenant'
    },
    quizzes: {
        id: 'quizzes',
        label: 'Quizzes'
    }
};

exports.PATIENT_MANAGEMENT = {
    headline: 'Patient Management',
    noPatients: 'You have no patients',
    filterOption: {
        id: 'patients',
        label: 'Patients'
    }
};

exports.RESULT = {
    commentByDoctor: 'Comment left by the doctor:',
    aboutPatient: 'About the patient',
    viewUserInfo: 'View more user info',
    tableView: 'Table view',
    chartView: 'Chart view'
};

exports.RESULTS_MANAGEMENT = {
    headline: 'Result Management',
    noResults: 'You have no results to look at yet. You will be updated on your e-mail when a new result comes in. Thank you!',
    manuallyAddedMessage: 'Manually added'
};

exports.RESULTS_TABLE = {
    searchByIdPlaceholderText: 'Search by id',
    filterByPatientPlaceholderText: 'Filter by patient',
    filterByDoctorPlaceholderText: 'Filter by doctor',
    idLabel: 'ID:',
    nameLabel: 'Name:',
    genderLabel: 'Gender:',
    ageLabel: 'Age:',
    dateAddedCell: 'Date Added',
    dateFinishedCell: 'Finished on',
    kitTypeLabel: 'Kit Type',
    resultIdLabel: 'Result ID',
    notFinishedMessage: 'Not finished',
    resultAssignedState: 'Result assigned',
    resultReviewedState: 'Result reviewed',
    resultNotAssignedMessage: 'Result not assigned',
    resultAssignedSuccessMessage: 'Result assigned successfully',
    resultNotAssignedErrorMessage: 'Result not assigned due to an error',
    unassignSuccessMessage: 'You have been unassigned successfully',
    unassignErrorMessage: 'Could not unassign you due to an error',
    filterOptions: {
        all: {
            id: 'all',
            label: 'All'
        },
        ready: {
            id: 'ready',
            label: 'Ready to review'
        },
        finished: {
            id: 'finished',
            label: 'Finished'
        }
    }
};

exports.USER_DATA_INFO = {
    headline: 'User Info',
    bloodResults: "User's blood results",
    userQuizzes: 'User Quizzes'
};

exports.USERS_TABLE = {
    filterOptions: {
        all: {
            id: 'all',
            label: 'All'
        },
        patient: {
            id: 'patient',
            label: 'Patients'
        },
        doctor: {
            id: 'doctor',
            label: 'Doctors'
        },
        admin: {
            id: 'admin',
            label: 'Admins'
        }
    },
    genderLabel: 'Gender:',
    ageLabel: 'Age:',
    heightLabel: 'Height:',
    weightLabel: 'Weight:',
    expiresOn: 'Expires on:',
    searchInputPlaceholder: 'Search by id, name, email',
    outcomeStatus: 'Outcome status:',
    unavailable: 'UNAVAILABLE',
    waitingForOnboarding: 'WAITING FOR ONBOARDING'
};

exports.USER_RESULTS = {
    bloodKitsHeadline: 'Blood Kits & Results',
    bloodResultsHeadline: 'Blood Results',
    noSubscription: 'You do not have yet any subscription for blood testing kits',
    nothingFound: 'Nothing found',
    filterOptions: {
        all: {
            id: 'all',
            label: 'All'
        },
        result: {
            id: 'result',
            label: 'With a result'
        }
    }
};

exports.USER_STATS = {
    kitOrderMessage:
        'Your blood testing kit will arrive to you soon. You will receive updates on your e-mail.\nYou can start using Lola by connecting a wearable device on the wearables page.',
    noKitOrderMessage: 'You have an order for a blood testing kit, but there are no results available yet.\nYou will receive updates on your e-mail.'
};

exports.USER_WEARABLES = {
    dateFilter: 'Date Filter',
    nativeWearables: 'For Samsung/Google Fit users',
    noWearables: 'No wearables connected',
    headlines: {
        connectedWearables: 'Connected wearables',
        disconnectedWearables: 'Disconnected wearables'
    },
    notifications: {
        connectionSuccess: 'Connection was successfully established!',
        connectionError: 'Wear connection failed'
    },
    providers: {
        apple: 'APPLE',
        garmin: 'GARMIN',
        samsung: 'SAMSUNG'
    }
};

exports.SIGNUP_STAGES = {
    basicInfo: {
        id: 'basic-data-collection',
        title: 'Tell us more about yourself'
    },
    subscription: {
        id: 'subscriptions',
        title: 'Subscription details'
    }
};

exports.LOLA_BUDDY = {
    checkIn: {
        type: 'check-in'
    },
    diet: {
        type: 'diet',
        headline: "Let's get munchin'",
        description: 'You have no diet plans yet. Get tailored meal plans for a healthier lifestyle!',
        buttonText: "Let's set up your diet plan!"
    },
    fitness: {
        type: 'fitness',
        headline: "Let's start training!",
        description:
            "You don't have any workout plans yet. Begin your fitness journey by receiving a personalized plan for the next 7 days. Once you begin your first plan, you will receive an additional day each day.",
        buttonText: "Let's set up your fitness plan!"
    },
    habit: {
        type: 'habit'
    },
    calories: 'Calories',
    cooldown: 'Cooldown',
    exercises: 'Exercises',
    fats: 'Fats:',
    ingredients: 'Ingredients',
    generateErrorMessage: 'Error in generation, please regenerate',
    headline: 'Buddy',
    noTasks: 'No tasks',
    planQuestion: 'Do you want a personalized diet plan, training plan, or both?',
    protein: 'Protein',
    preparationSteps: 'Perparation steps:',
    regenerateStartMessage: 'Task regenerating...',
    regenerateSuccessMessage: 'Task swapped successfully.',
    regenerateEndMessage: 'Success! Task generation completed.',
    regenerateErrorMessage: 'Error! There has been an error regenerating the task.',
    status: 'Status:',
    tasksFor: 'Tasks for',
    youHavePlanFor: 'Your have plan for',
    warmup: 'Warmup'
};

const welcomeSlide = {
    title: 'Welcome to Your Performance Lab',
    text: 'You are here because you know that by prioritizing your health, performance, and longevity on a daily basis, you are investing in a better quality of life for yourself in the long term.',
    img: {
        src: require('../assets/svg/onboarding-screens/welcome-lola.svg')
    }
};

const wearablesSlide = {
    title: 'Wearables and key metrics',
    text: 'Connect your wearable to the Lola app for personalized daily metrics and insights to enhance your health and performance, ultimately improving your quality of life and longevity with the support of our world-class medical and technical team.',
    img: {
        src: require('../assets/svg/onboarding-screens/connect-wearables.svg')
    }
};

const tipsSlide = {
    title: 'Sustainable & easy-to-implement top tips',
    text: 'Access our complete health library of top tips on optimizing sleep, nutrition, hydration, and exercise to improve your mental, emotional, and physical well-being on a daily basis. We want you to feel at your best, whenever possible.',
    img: {
        src: require('../assets/svg/onboarding-screens/tips-tricks.svg')
    }
};

exports.ONBOARDING_SCREENS = {
    kitsAndWearableSlides: [
        welcomeSlide,
        {
            title: 'Data Guided Insights & Actionable Support',
            text: 'Our goal is to empower you to take control of your health, performance, and longevity by connecting your wearable devices to our Lola app and overlaying your regular blood test results to give you personalized insights to optimize your well-being.',
            img: {
                src: require('../assets/svg/onboarding-screens/personalized-metrics.svg')
            }
        },
        {
            title: 'Subscriptions are designed with you in mind',
            text: "Select a subscription tailored to your specific needs: female health longevity, and vibrancy or male health, longevity and performance. Together, we'll enhance your daily and long-term health goals.",
            img: {
                src: require('../assets/svg/onboarding-screens/select-a-plan.svg')
            }
        },
        {
            title: 'Blood health with accurate results',
            text: 'Lola home blood tests are recommended monthly, with frequency adjustable to fit individual needs (monthly or every 2-3 months). Lola doctor reviews results within 72 hours, offering data-driven insights and recommendations for peace of mind.',
            img: {
                src: require('../assets/svg/onboarding-screens/receive-blood-kit.svg')
            }
        },
        wearablesSlide,
        tipsSlide
    ],
    manualResultsAndWearableSlides: [
        welcomeSlide,
        {
            title: 'Data Guided Insights & Actionable Support',
            text: 'Our goal is to empower you to take control of your health, performance, and longevity by connecting your wearable devices to our Lola app and manually inputting your regular blood test results to give you personalized insights to optimize your well-being.',
            img: {
                src: require('../assets/svg/onboarding-screens/personalized-metrics.svg')
            }
        },
        {
            title: 'Subscriptions are designed with you in mind',
            text: "Select a subscription tailored to your specific needs: female health, longevity, and vibrancy or male health, longevity and performance. Together, we'll enhance your daily and long-term health goals using both wearable data and your manually added blood test results.",
            img: {
                src: require('../assets/svg/onboarding-screens/select-a-plan.svg')
            }
        },
        {
            title: 'Blood health with accurate results',
            text: 'Get your blood test results uploaded into the Lola app. Our team of medical professionals will review your data, offering data-driven insights and recommendations for peace of mind.',
            img: {
                src: require('../assets/svg/onboarding-screens/receive-blood-kit.svg')
            }
        },
        wearablesSlide,
        tipsSlide
    ],
    wearablesOnlySlides: [
        welcomeSlide,
        {
            title: 'Data Guided Insights & Actionable Support',
            text: 'Our goal is to empower you to take control of your health, performance, and longevity by connecting your wearable devices to our Lola app. This will give you personalized insights to optimize your well-being without the need for blood test data.',
            img: {
                src: require('../assets/svg/onboarding-screens/personalized-metrics.svg')
            }
        },
        {
            title: 'Subscriptions are designed with you in mind',
            text: "Select a subscription tailored to your specific needs: female health longevity, and vibrancy or male health, longevity and performance. Together, we'll enhance your daily and long-term health goals using wearable data alone.",
            img: {
                src: require('../assets/svg/onboarding-screens/select-a-plan.svg')
            }
        },
        wearablesSlide,
        tipsSlide
    ]
};
