<template>
    <aside class="sidenav" :class="{ closed: !isOpen }">
        <div class="top d-flex align-items-center responsive-justify">
            <div class="logo">
                <button class="btn" @click="redirectHome">
                    <img v-show="isOpen" alt="Lola Logo" width="110" src="../assets/img/Lola_logo_512x512.svg" />
                </button>
            </div>
            <button v-show="isOpen" class="btn btn-link close-btn" @click="emitToggleEvent">
                <i class="fa-solid fa-xmark" />
            </button>
            <button v-show="!isOpen" class="btn btn-link close-btn" @click="emitToggleEvent">
                <i class="fa-solid fa-bars" />
            </button>
        </div>
        <div class="sidebar d-flex flex-column justify-content-between" style="max-height: 92vh; height: 100%">
            <b-nav v-if="!hideForPatient && !isLogout" v-model="bnav" vertical>
                <b-nav-item v-for="link in links" :key="link.id" :to="link.path" :value="link.id" class="b-nav-link">
                    <div class="icon-link">
                        <i :class="link.icon" />
                        <span>{{ link.title }}</span>
                    </div>
                </b-nav-item>
            </b-nav>
            <profile-dropdown />
        </div>
    </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileDropdown from '@/components/profile-dropdown';
import { formatRoute } from '@/utils/utils';
import { Routes } from '../utils/constants';

export default {
    components: { ProfileDropdown },
    props: {
        isOpen: {
            type: Boolean
        },
        hideForPatient: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            bnav: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        }),
        isLogout() {
            return this.$route.path.includes(Routes.LOGOUT);
        },
        links() {
            let links = [];
            // example - path: { name: "bookmarks", query: { bookmark: "important" } }
            // if (!this.user.isRegistered) return links;

            if (this.user.isDoctor || this.user.isAdmin) {
                links.push({
                    id: 1,
                    icon: 'fa-solid fa-home',
                    title: 'Dashboard',
                    path: { name: Routes.DASHBOARD }
                });
                links.push({
                    id: 5,
                    icon: 'fa-solid fa-tint',
                    title: 'Results',
                    path: { name: Routes.RESULTS_MANAGEMENT }
                });
                links.push({
                    id: 4,
                    icon: 'fa-solid fa-users',
                    title: 'Patients',
                    path: { name: Routes.PATIENTS }
                });
            }

            if (this.user.isAdmin) {
                links.push({
                    id: 6,
                    icon: 'fa-solid fa-list',
                    title: 'Orders',
                    path: { name: Routes.ORDER_MANAGEMENT }
                });
            }

            links = links.filter((link) => {
                const accessBasedOnUserProperty = link.meta?.accessBasedOnUserProperty;
                if (accessBasedOnUserProperty) {
                    return accessBasedOnUserProperty.every((property) => this.user[property]);
                }
                return true;
            });

            links = links.filter((link) => {
                const tenantPermissions = link.meta?.tenantPermissions;
                if (tenantPermissions) {
                    return tenantPermissions.every((permission) => this.user.hasAnyPermission(permission));
                }
                return true;
            });

            return links;
        }
    },
    methods: {
        emitToggleEvent() {
            this.$emit('toggle', !this.isOpen);
        },
        redirectHome() {
            const currentPath = this.$router.currentRoute.path;

            if ((this.user.isAdmin || this.user.isDoctor) && currentPath !== formatRoute(Routes.DASHBOARD)) {
                this.$router.push(Routes.DASHBOARD);
            }

            if (!this.user.isAdmin && !this.user.isDoctor) {
                this.$router.push('/');
            }
        }
    }
};
</script>
