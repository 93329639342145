<template>
    <div>
        <div class="pb-5">
            <div class="table-filters">
                <div class="dropdown-filter">
                    <b-dropdown :text="filterMarker.label" variant="light" class="w-100">
                        <b-dropdown-item
                            v-for="label in filterOptions"
                            :key="label.id"
                            :active="label.id === filterMarker.id"
                            @click="filterMarker = label"
                        >
                            {{ label.label }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="search-bar">
                    <span id="search-addon" class="input-group-text border-0 bg-transparent">
                        <i class="fas fa-search" />
                    </span>
                    <input v-model="testSearch" placeholder="Search marker" type="text" class="border-0" />
                </div>
            </div>

            <b-form-checkbox
                v-if="isThereAnyHistoricalData"
                class="pt-1 my-3 small"
                switch
                size="sm"
                @click.prevent
                @change="toggleCollapseExpandAll()"
            >
                <small> {{ collapseAll ? 'Collapse' : 'Expand' }} All Historical Data </small>
            </b-form-checkbox>

            <h4 v-if="totalMarkerCount()" class="text-center mt-3">
                Total markers tested: {{ totalMarkerCount() }} ({{ totalInvalidMarkerCount() }} are invalid)
            </h4>

            <div v-for="(setOfMarkers, index) in formattedCategoriesWithMarkers" :key="index" class="mt-3">
                <div class="card col-12">
                    <div class="card-inner border-0">
                        <div class="category-header">
                            <h4 class="category-header__h4">
                                <img :src="setOfMarkers.categoryIcon" height="28" class="mr-1" />
                                {{ setOfMarkers.categoryName }}
                                <small
                                    ><small>(Markers: {{ setOfMarkers.items.length }})</small>
                                </small>
                            </h4>
                            <div class="w-100 text-center mb-2">
                                <ResultSummary :result="result" :category="setOfMarkers.categoryId" :align-center="true" :center-on-mobile="true" />
                            </div>
                        </div>
                        <div class="card">
                            <span class="card-inner border-0" style="white-space: pre-line">
                                {{ setOfMarkers.categoryDescription }}
                            </span>
                        </div>
                        <div v-if="!setOfMarkers.items?.length" class="w-100 text-center">
                            No markers found
                            <div class="d-flex justify-content-center align-items-center">
                                <img src="../assets/svg/empty-state/empty-folder.svg" class="empty-state-image" />
                            </div>
                        </div>
                        <div v-if="setOfMarkers.items?.length" class="w-100">
                            <div class="card-body">
                                <b-table striped stacked="md" hover :fields="fields" :items="setOfMarkers.items">
                                    <template #cell(testName)="row">
                                        <div>
                                            {{ row.item.testName }}
                                            <small
                                                ><small>(Internal: {{ row.item.testInternalCode }})</small></small
                                            ><span
                                                v-if="row.item.description"
                                                v-b-tooltip.hover.top
                                                class="ml-1"
                                                :title="`${row.item.description} -- (marker internal code: ${row.item.testInternalCode})`"
                                            >
                                                <font-awesome-icon icon="fa fa-info-circle" />
                                            </span>
                                        </div>
                                    </template>
                                    <template #cell(referenceRange)="row">
                                        <div
                                            v-if="row.item.comments?.length || row.item.referenceRange"
                                            class="d-flex flex-column justify-content-center my-1"
                                        >
                                            <span v-if="row.item.referenceRange" class="mb-1">General: {{ row.item.referenceRange }}</span>
                                            <span v-for="(comment, index2) in row.item.comments" :key="index2" class="mb-1">
                                                {{ comment }}
                                            </span>
                                        </div>
                                    </template>
                                    <template #cell(value)="row">
                                        <!-- Current value with trend icon next to it -->
                                        <div class="d-flex align-items-center">
                                            <b v-if="row.item.history?.length">Current:</b>
                                            {{ row.item.value }}

                                            <!-- Trend icon with thicker appearance -->
                                            <b-icon
                                                class="ml-2"
                                                v-if="
                                                    row.item.history?.length > 1 &&
                                                    row.item.value > getLastValueBeforeCurrent(row.item.history, row.item.value)?.value
                                                "
                                                icon="arrow-up"
                                                variant="success"
                                                font-scale="1.2"
                                                style="font-weight: bold"
                                            ></b-icon>
                                            <b-icon
                                                class="ml-2"
                                                v-if="
                                                    row.item.history?.length > 1 &&
                                                    row.item.value < getLastValueBeforeCurrent(row.item.history, row.item.value)?.value
                                                "
                                                icon="arrow-down"
                                                variant="danger"
                                                font-scale="1.2"
                                                style="font-weight: bold"
                                            ></b-icon>
                                            <b-icon
                                                class="ml-2"
                                                v-if="
                                                    row.item.history?.length > 1 &&
                                                    row.item.value === getLastValueBeforeCurrent(row.item.history, row.item.value)?.value
                                                "
                                                icon="dash"
                                                variant="secondary"
                                                font-scale="1.2"
                                                style="font-weight: bold"
                                            ></b-icon>
                                        </div>

                                        <!-- Previous value with muted and smaller text -->
                                        <div v-if="row.item.history?.length > 1" class="mt-2 text-muted">
                                            <small
                                                ><b>Previous ({{ getLastValueBeforeCurrent(row.item.history, row.item.value)?.date }}):</b>
                                                {{ getLastValueBeforeCurrent(row.item.history, row.item.value)?.value }}</small
                                            >
                                        </div>

                                        <br />
                                        <small v-if="row.item.history?.length">
                                            <b>Historical Data:</b>
                                            <b-button size="sm" variant="link" @click="toggleCollapse(row.index)">
                                                <i :class="isCollapsed(row.index) ? 'fa fa-plus mr-1' : 'fa fa-minus mr-1'" />
                                                {{ isCollapsed(row.index) ? 'Expand' : 'Collapse' }}
                                            </b-button>
                                        </small>
                                        <b-collapse :id="'collapse-' + row.index" v-model="collapsedRows[row.index]">
                                            <small v-if="row.item.history?.length" class="d-flex flex-column justify-content-center my-1">
                                                <span v-for="(history, index2) in row.item.history" :key="index2" class="mb-1">
                                                    <span class="text-gray">{{ history.date }}:</span> {{ history.value }}
                                                    <small
                                                        ><b>(Flag: {{ history.abnormalFlags.message }})</b></small
                                                    >
                                                </span>
                                            </small>
                                        </b-collapse>
                                    </template>

                                    <template #cell(abnormalFlags)="row">
                                        <div v-if="row.item.abnormalFlags" class="d-flex align-items-center my-1">
                                            <span :class="getCardStyle(row.item)">
                                                <b> {{ row.item.abnormalFlags.message }} </b>
                                            </span>
                                        </div>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!formattedCategoriesWithMarkers?.length" class="w-100 text-center mt-3">
                No markers found
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/svg/empty-state/empty-folder.svg" class="empty-state-image" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ABNORMAL_FLAGS, ABNORMAL_FLAGS_TAGGED, darkGreyColor, greenColor, rawLocalTest, bloodCategoryDetails } from '@/utils/constants';
import moment from 'moment';
import _ from 'lodash';
import ResultSummary from './result-summary';

export default {
    components: { ResultSummary },
    props: {
        categoriesWithMarkers: { required: true, type: Object },
        order: { type: Object },
        result: {
            type: Object
        }
    },
    data() {
        return {
            bloodCategoryDetails,
            formattedCategoriesWithMarkers: null,
            defaultCategoriesWithMarkers: null,
            fields: [
                { key: 'testName' },
                { key: 'referenceRange', label: 'Reference Ranges & Comments' },
                { key: 'value' },
                { key: 'abnormalFlags' }
            ],
            currentCategoriesWithMarkers: {},
            ABNORMAL_FLAGS,
            ABNORMAL_FLAGS_TAGGED,
            testSearch: '',
            actionsState: false,
            filterMarker: { id: 'all', label: 'All' },
            filterOptions: [
                { id: 'all', label: 'All' },
                { id: 'good', label: 'Good' },
                { id: 'bad', label: 'Deficient' },
                { id: 'undefined', label: 'Invalid' }
            ],
            sampleRawTest: rawLocalTest,
            collapsedRows: {},
            collapseAll: false,
            expandAll: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        }),
        isThereAnyHistoricalData() {
            return this.formattedCategoriesWithMarkers.some((cat) => cat.items.some((item) => item.history.length));
        }
    },
    watch: {
        collapseAll(newValue) {
            if (newValue) {
                this.toggleCollapseAll();
            }
        },
        expandAll(newValue) {
            if (newValue) {
                this.toggleExpandAll();
            }
        },
        testSearch: {
            handler(val) {
                this.filterMarker = { id: 'all', label: 'All' };

                if (this.defaultCategoriesWithMarkers && this.defaultCategoriesWithMarkers.length) {
                    if (!val) {
                        this.formattedCategoriesWithMarkers = this.defaultCategoriesWithMarkers;
                    } else {
                        const txt = val.toLowerCase();
                        this.formattedCategoriesWithMarkers = this.defaultCategoriesWithMarkers
                            .map((result) => {
                                if (result.categoryName.toLowerCase().includes(txt)) return result;
                                if (!result.items.some((itm) => itm.testName.toLowerCase().includes(txt))) return null;

                                return {
                                    ...result,
                                    items: result.items.filter((itm) => itm.testName.toLowerCase().includes(txt))
                                };
                            })
                            .filter((x) => x);
                    }
                }
            }
        },
        'filterMarker.id': {
            handler(val) {
                this.testSearch = '';

                if (this.defaultCategoriesWithMarkers && this.defaultCategoriesWithMarkers.length) {
                    if (val === 'all') {
                        this.formattedCategoriesWithMarkers = this.defaultCategoriesWithMarkers;
                    } else {
                        this.formattedCategoriesWithMarkers = this.defaultCategoriesWithMarkers
                            .map((result) => {
                                return {
                                    ...result,
                                    items: result.items.filter(
                                        (itm) =>
                                            ABNORMAL_FLAGS_TAGGED[itm.abnormalFlagCode] === val || (val === 'good' && itm.abnormalFlagCode === 'NAF')
                                    )
                                };
                            })
                            .filter((x) => x && x.items && x.items.length);
                    }
                }
            }
        }
    },
    async created() {
        this.currentCategoriesWithMarkers = _.cloneDeep(this.categoriesWithMarkers);
        this.formattedCategoriesWithMarkers = this.formatCatWithMarkersForDoctor(this.categoriesWithMarkers);
        this.defaultCategoriesWithMarkers = _.cloneDeep(this.formattedCategoriesWithMarkers);
    },
    methods: {
        toggleCollapse(index) {
            this.$set(this.collapsedRows, index, !this.collapsedRows[index]);
        },
        isCollapsed(index) {
            return this.collapsedRows[index];
        },
        toggleCollapseAll() {
            this.expandAll = false;
            this.formattedCategoriesWithMarkers.forEach((cat) =>
                cat.items?.forEach((item, index) => {
                    this.$set(this.collapsedRows, index, true);
                })
            );
            this.collapseAll = true;
        },
        toggleExpandAll() {
            this.collapseAll = false;
            this.formattedCategoriesWithMarkers.forEach((cat) =>
                cat.items?.forEach((item, index) => {
                    this.$set(this.collapsedRows, index, false);
                })
            );
            this.expandAll = true;
        },
        toggleCollapseExpandAll() {
            if (this.collapseAll) {
                this.toggleExpandAll();
            } else {
                this.toggleCollapseAll();
            }
        },
        totalMarkerCount() {
            return _.reduce(this.categoriesWithMarkers, (acc, cat) => acc + cat.markers.length, 0);
        },
        totalInvalidMarkerCount() {
            return _.reduce(this.categoriesWithMarkers, (acc, cat) => acc + cat.markers.filter((m) => m.invalid).length, 0);
        },
        formatCatWithMarkersForDoctor(catWithMarkers) {
            const catWithMarkersFormatted = [];
            _.each(catWithMarkers, (categoryData) => {
                if (categoryData?.markers?.length) {
                    const categoryInfo = categoryData.categoryDetails;

                    const markersPerCat = {
                        categoryName: categoryInfo.name,
                        categoryId: categoryInfo.id,
                        categoryDescription: categoryInfo.description,
                        categoryIcon: bloodCategoryDetails[categoryInfo.id]?.icon || bloodCategoryDetails.default.icon,
                        items: categoryData?.markers.map((marker) => {
                            return {
                                testName: marker.testName,
                                id: marker.setIdOBX,
                                units: marker.units,
                                referenceRange: marker.referenceRange,
                                value: `${marker.observationValue} ${_.isNaN(Number(marker.observationValue)) ? '' : marker.units || ''}`,
                                history: _.orderBy(
                                    marker.history?.map((h) => ({
                                        date: moment(new Date(h.dateAdded)).format('DD MMM YYYY'),
                                        value: `${h.observationValue} ${
                                            _.isNil(h.observationValue) || _.isNaN(Number(h.observationValue)) ? '' : h.units || ''
                                        }`,
                                        abnormalFlags: h.abnormalFlags
                                    })),
                                    (d) => moment(d.date).toDate(),
                                    ['desc']
                                ),
                                numberValue: _.isNaN(Number(marker.observationValue)) ? marker.observationValue : Number(marker.observationValue),
                                invalid: marker.invalid,
                                description: marker.description,
                                abnormalFlags: marker.abnormalFlags,
                                abnormalFlagCode: marker.abnormalFlags.flag,
                                observationResultStatus: marker.observationResultStatus.message,
                                testInternalCode: marker.testInternalCode,
                                comments: marker.comments ? marker.comments.map((c) => c.comment) : [],
                                chartType: marker.chartType
                            };
                        })
                    };

                    catWithMarkersFormatted.push(markersPerCat);
                }
            });

            return catWithMarkersFormatted;
        },
        getLastValueBeforeCurrent(history, currentValue) {
            // Filter out values before the current value in time
            const pastMarkers = history.filter((item) => item.value !== currentValue);

            // Sort by date in descending order to get the most recent one
            const sortedMarkers = pastMarkers.sort((a, b) => new Date(b.date) - new Date(a.date));

            // Return the most recent value before the current one, or null if none exists
            return sortedMarkers.length ? sortedMarkers[0] : null;
        },
        getCardStyle(marker) {
            return `analysis-title__status ${
                marker.invalid ? darkGreyColor.icon : marker.abnormalFlagCode ? ABNORMAL_FLAGS[marker.abnormalFlagCode].icon : greenColor.icon
            }`;
        }
    }
};
</script>
<style scoped>
.sticky-space {
    margin-bottom: 360px !important;
}

.max-width {
    max-width: 60%;
}

@media screen and (max-width: 778px) {
    .max-width {
        max-width: 100%;
    }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 778px) {
    .filter-button-group {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

.filter-button-group {
    display: flex;
}

.table-filters {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 778px) {
        flex-direction: column;
    }
}

.dropdown-filter {
    display: flex;
    width: 25%;
    margin-right: 1rem;

    @media screen and (max-width: 778px) {
        width: 100%;
        margin-right: 0;
    }
}

.search-bar {
    width: 75%;

    @media screen and (max-width: 778px) {
        width: 100%;
        margin-top: 1rem;
    }
}

.category-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &__h4 {
        width: 30%;
        text-align: left;
    }

    @media screen and (max-width: 778px) {
        flex-direction: column;

        &__h4 {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
        }
    }
}
</style>
