<template>
    <div>
        <div v-if="!withoutFilters" class="mb-2 d-flex flex-column">
            <div class="form-group w-100 mb-2">
                <div class="search-bar w-100">
                    <span id="search-addon" class="input-group-text border-0 bg-transparent">
                        <i class="fas fa-search" />
                    </span>
                    <input
                        :disabled="isLoading"
                        v-model="searchTerm"
                        placeholder="'Search by result id, user id/name/email, order id, doctor name'"
                        class="border-0"
                    />
                </div>
            </div>
            <div>
                <label style="font-size: 1rem; display: flex; align-items: center">
                    <input
                        v-model="finishedStatus"
                        type="checkbox"
                        :disabled="isLoading"
                        style="margin-right: 0.5rem"
                        @change="changeFinishedStatus"
                    />
                    {{ currentUser.useDoctorLanguage ? 'Show your finished results' : 'Show finished results' }}
                </label>
            </div>
        </div>
        <loader :listen="['result/getAllResults', 'result/getAllUserResults']">
            <div v-if="total && !noPagination" class="text-left text-lg mt-4 font-weight-bold">Total Results: {{ total }}</div>
            <div class="b-table-wrapper mt-3">
                <b-table
                    id="results-table"
                    striped
                    stacked="lg"
                    hover
                    :items="results"
                    :fields="fields"
                    :per-page="0"
                    :current-page="!noPagination ? currentPage : 1"
                    show-empty
                >
                    <template #cell(resultDetails)="row">
                        <!-- <b-button v-if="row.detailsShowing" key="01" size="sm" class="mr-2" @click="row.toggleDetails">
                        <i class="fas fa-chevron-down" />
                    </b-button> -->
                        <!-- <b-button
                        v-else key="02" size="sm" class="mr-2" @click="row.toggleDetails">
                        <i class="fas fa-chevron-right" />
                    </b-button> -->
                        <div class="card flex-column flex-lg-row p-1 py-2 py-lg-1 m-0">
                            <div class="col-auto col-lg">
                                <div class="d-flex flex-sm-row flex-column small">
                                    <span class="secondary-label mr-2"> Result Info</span>
                                    <div v-if="row.item.hasInvalidMarkers">
                                        <div class="d-flex align-items-start justify-content-start small">
                                            <span class="analysis-title analysis-title__status red small px-3">
                                                {{ row.item.invalidMarkersCount }} INVALID MARKERS
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="small">
                                    <small>
                                        <b>ID:</b> {{ row.item.id }} <br />
                                        <b>Order ID:</b> {{ row.item.orderId }} <br />
                                        <b v-if="row.item.resolution" class="text-danger">Resolution:</b> <b>{{ row.item.resolution }}</b>
                                    </small>
                                </div>
                            </div>
                            <div v-if="row.item.order?.testName" class="col-auto col-lg-3">
                                <div class="secondary-label small">
                                    {{ RESULTS_TABLE.kitTypeLabel }}
                                </div>
                                <div class="small">
                                    {{ row.item.order?.testName }}
                                </div>
                            </div>
                            <div class="col-auto col-lg">
                                <small class="card flex-column p-2">
                                    <div class="mb-1">
                                        <span class="font-weight-bold"> Date Updated: </span>{{ row.item.displayDateUpdated || 'Not updated' }}
                                    </div>
                                    <div class="mb-1">
                                        <span class="font-weight-bold"> Lab Added: </span>{{ row.item.displayDateAdded || 'Not added yet' }}
                                    </div>
                                    <div>
                                        <span class="font-weight-bold"> Finished On: </span>{{ row.item.displayFinishedDate || 'Not finished' }}
                                    </div>
                                </small>
                            </div>
                        </div>
                    </template>
                    <template v-if="!withoutPatient" #cell(patientInfo)="row">
                        <div class="d-flex w-100 align-items-center small">
                            <img :src="row.item.user.profilePictureUrl" class="rounded-circle" width="40" height="40" />
                            <div class="d-flex flex-column w-100 ml-2">
                                <div><span class="font-weight-bold"> Name </span>{{ row.item.user?.name }}</div>
                                <div><span class="font-weight-bold"> Lab Form Name </span>{{ patientNameInResult(row.item.result) }}</div>
                                <small
                                    ><small>
                                        <span class="font-weight-bold"> ID: </span>{{ row.item.user.id }}
                                        <div><span class="font-weight-bold"> Email: </span>{{ row.item.user?.email }}</div>
                                        <!-- <div>
                                            <span class="font-weight-bold"> Personal Info: </span> {{ row.item.user.gender }} |
                                            {{ row.item.user.age ? row.item.user.age + ` yo (${row.item.user.dob})` : 'Not provided' }}
                                        </div> -->
                                    </small></small
                                >
                            </div>
                        </div>
                    </template>
                    <!-- <template #row-details="row">
                <b-card>
                    <b-row>
                        <b-col class="text-sm-center">
                            <h6>Order Details</h6>
                        </b-col>
                    </b-row>
                    <b-row v-if="row.item.id" class="mb-2">
                        <b-col class="text-sm-right">
                            <b>Order Number</b>
                        </b-col>
                        <b-col>{{ row.item.id }}</b-col>
                    </b-row>
                </template>
                <template #row-details="row">
                    <b-card>
                        <b-row>
                            <b-col class="text-sm-center">
                                <h6>Order Details</h6>
                            </b-col>
                        </b-row>
                        <b-row v-if="row.item.id" class="mb-2">
                            <b-col class="text-sm-right">
                                <b>Order Number</b>
                            </b-col>
                            <b-col>{{ row.item.id }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                            <b-col class="text-sm-right">
                                <b>Order Date</b>
                            </b-col>
                            <b-col>{{ row.item.displayPlacedDate }}</b-col>
                        </b-row>
                    </b-card>
                    <b-card>
                        <b-row>
                            <b-col class="text-sm-center">
                                <h6>User Details</h6>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col class="text-sm-right">
                                <b>Date of Birth</b>
                            </b-col>
                            <b-col>{{ row.item.patientDOB }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                            <b-col class="text-sm-right">
                                <b>Gender</b>
                            </b-col>
                            <b-col>{{ row.item.patientGender }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                            <b-col class="text-sm-right">
                                <b>Pacient Contacted</b>
                            </b-col>
                            <b-col>{{ row.item.urgencySolved && row.item.dateContacted ? `${row.item.urgencySolved} on ${row.item.dateContacted}` : 'No' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                            <b-col class="text-sm-right">
                                <b>Urgency Reason</b>
                            </b-col>
                            <b-col>{{ row.item.urgencyReason ? row.item.urgencyReason : 'No details' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                            <b-col class="text-sm-right">
                                <b>Last Updated</b>
                            </b-col>
                            <b-col>{{ row.item.dateUpdated }}</b-col>
                        </b-row>
                    </b-card>
                </template> -->
                    <!-- <template #cell(urgent)="row">
                    <p v-if="row.item !== ''" class="mb-0 analysis-title__status" :class="row.item.urgent
                        ? 'red'
                        : 'green'">
                        {{ row.item.urgent ? 'Urgent' : 'Not Urgent' }}
                    </p>
                </template> -->
                    <template #cell(actions)="row">
                        <div class="d-flex flex-column align-items-start justify-content-center">
                            <!-- Button for viewing or reviewing result -->
                            <router-link
                                v-if="shouldShowViewReviewButton(row.item)"
                                tag="button"
                                class="m-0 p-0 btn btn-link justify-content-start small"
                                :to="{
                                    name: 'Result',
                                    params: { id: row.item.id },
                                    query: { userId: row.item.userId }
                                }"
                            >
                                {{ getViewReviewButtonText(row.item) }}
                            </router-link>

                            <!-- Button for assigning result -->
                            <loading-btn
                                v-if="shouldShowAssignResultButton(row.item)"
                                :listen="['result/assignDoctor']"
                                class="m-0 p-0 btn btn-link justify-content-start small"
                                @click.prevent="assignResult(row.item)"
                            >
                                {{ BUTTONS.assignResult }}
                            </loading-btn>

                            <!-- Button for unassigning result -->
                            <loading-btn
                                v-if="shouldShowUnassignResultButton(row.item)"
                                :listen="['result/unassignDoctor']"
                                class="m-0 p-0 btn btn-link justify-content-start my-1 small"
                                @click.prevent="unassignResult(row.item)"
                            >
                                {{ BUTTONS.unassignResult }}
                            </loading-btn>

                            <div
                                v-if="row.item.result && row.item?.doctor"
                                class="d-flex flex-column align-items-start justify-content-center text-center"
                                style="width: 100%"
                            >
                                <span
                                    v-if="row.item?.doctor?.id !== loggedUser.id"
                                    v-b-tooltip.hover.left
                                    :title="`${GENERAL.doctor} ${row.item.doctor?.email}`"
                                    class="small"
                                >
                                    {{ !row.item.finished ? RESULTS_TABLE.resultAssignedState : RESULTS_TABLE.resultReviewedState }}
                                    <font-awesome-icon icon="fa fa-info-circle" />
                                </span>
                                <div class="d-flex align-items-center justify-content-center small">
                                    <span v-if="row.item?.doctor?.id === loggedUser.id" class="analysis-title analysis-title__status small px-3">{{
                                        GENERAL.you
                                    }}</span>
                                </div>
                            </div>
                            <div v-if="row.item.result && !row.item.doctor" class="small text-left">
                                {{ RESULTS_TABLE.resultNotAssignedMessage }}
                            </div>
                        </div>

                        <b-dropdown
                            id="dropdown-grouped"
                            text="Actions"
                            class="mx-2"
                            variant="primary"
                            v-can-access.role="'admin'"
                            :disabled="$store.state.loading[`result/deleteResult/${row.item.id}`]"
                        >
                            <b-dropdown-group v-can-access.role="'admin'" id="dropdown-group-1" header="Danger">
                                <b-dropdown-item-button v-can-access.role="'admin'" @click="onDeleteResult(row.item.id)">
                                    Delete Result
                                </b-dropdown-item-button>
                            </b-dropdown-group>
                        </b-dropdown>
                    </template>
                </b-table>
            </div>
            <b-pagination
                v-if="!noPagination"
                v-model="currentPage"
                align="center"
                :total-rows="total"
                :per-page="pageSize"
                aria-controls="results-table"
                @change="handlePageChange"
            />
            <div v-if="!results.length && showNoResultsImage" class="d-flex justify-content-center align-items-center">
                <img src="../assets/svg/empty-state/empty-folder.svg" class="empty-state-image" />
            </div>
        </loader>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BUTTONS, GENERAL, RESULTS_TABLE, STATUS_TYPES } from '@/utils/constants';
import moment from 'moment';
import _ from 'lodash';

export default {
    props: {
        results: {
            type: Array,
            default: () => []
        },
        buttonText: {
            type: String,
            default: BUTTONS.reviewResult
        },
        withoutFilters: {
            type: Boolean,
            default: false
        },
        withoutPatient: {
            type: Boolean,
            default: false
        },
        showNoResultsImage: {
            type: Boolean,
            default: false
        },
        noPagination: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            BUTTONS,
            GENERAL,
            RESULTS_TABLE,
            STATUS_TYPES,
            fields: [{ key: 'resultDetails' }, { key: 'patientInfo' }, { key: 'actions' }],
            currentPage: 1,
            searchTerm: null,
            selectedUser: null,
            selectedDoctor: null,
            finishedStatus: false,
            debouncedSearch: null
        };
    },
    computed: {
        ...mapGetters({
            loggedUser: 'user/currentUser',
            page: 'result/page',
            pageSize: 'result/pageSize',
            total: 'result/total',
            finished: 'result/finished',
            queryString: 'result/queryString',
            currentUser: 'user/currentUser'
        }),
        isLoading() {
            return !!this.$store.state.loading['result/getAllResults'] || !!this.$store.state.loading['result/getAllUserResults'];
        }
    },
    created() {
        if (this.withoutPatient) {
            this.fields = [{ key: 'resultDetails' }, { key: 'actions' }];
        }

        this.debouncedSearch = _.debounce(this.emitSearch, 700);

        this.currentPage = this.page;
        this.finishedStatus = this.finished;
    },
    watch: {
        searchTerm(value) {
            this.debouncedSearch(value);
        },
        queryString: {
            handler(value) {
                this.searchTerm = value;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions({
            assignDoctor: 'result/assignDoctor',
            unassignDoctor: 'result/unassignDoctor',
            getAllResults: 'result/getAllResults',
            deleteResult: 'result/deleteResult'
        }),
        beautifyDate(date) {
            return date ? moment(date).format('DD MMM YYYY') : '';
        },
        shouldShowViewReviewButton(item) {
            return item.result && (this.loggedUser.isDoctor || this.loggedUser.isAdmin);
        },
        getViewReviewButtonText(item) {
            const isFinishedOrAdmin = item.finished || this.loggedUser.isAdmin || !item.doctor;
            const isOnAnotherDoctor = item.doctor && item.doctor.id !== this.loggedUser.id;

            return isFinishedOrAdmin || isOnAnotherDoctor ? this.BUTTONS.seeResult : this.BUTTONS.review;
        },
        shouldShowAssignResultButton(item) {
            return (this.loggedUser.isDoctor || (this.loggedUser.isDoctor && this.loggedUser.isAdmin)) && item.result && !item.doctor;
        },
        shouldShowUnassignResultButton(item) {
            return (
                (this.loggedUser.isDoctor || (this.loggedUser.isDoctor && this.loggedUser.isAdmin)) &&
                item.doctor &&
                item.doctor.id === this.loggedUser.id &&
                item.result &&
                !item.finished
            );
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return;
            return 'table-warning';
        },
        async changeFinishedStatus() {
            this.currentPage = 1;

            this.$emit('pageChange', { page: this.currentPage, finishedStatus: this.finishedStatus });
        },
        async assignResult(tableItem) {
            try {
                await this.assignDoctor({ resultId: tableItem.id, doctorId: this.loggedUser.id });
                this.$notify({
                    type: STATUS_TYPES.success,
                    text: RESULTS_TABLE.resultAssignedSuccessMessage
                });
            } catch (e) {
                this.$notify({
                    type: STATUS_TYPES.error,
                    text: _.get(e.response, 'data.message', null) || e.message || RESULTS_TABLE.resultNotAssignedErrorMessage
                });
            }
        },
        async unassignResult(tableItem) {
            try {
                await this.unassignDoctor({ resultId: tableItem.id });

                this.$notify({
                    type: STATUS_TYPES.success,
                    text: RESULTS_TABLE.unassignSuccessMessage
                });
            } catch (e) {
                this.$notify({
                    type: STATUS_TYPES.error,
                    text: _.get(e.response, 'data.message', null) || e.message || RESULTS_TABLE.unassignErrorMessage
                });
            }
        },
        async handlePageChange(page) {
            this.currentPage = page;

            this.$emit('pageChange', { page, finishedStatus: this.finishedStatus, queryString: this.searchTerm });
        },
        emitSearch(value) {
            this.searchTerm = value;
            this.currentPage = 1;

            this.$emit('search', value);
        },
        patientNameInResult(result) {
            return result.PID?.patientName
                ?.filter(Boolean)
                ?.filter((x) => x !== 'MASTER')
                .join(' ');
        },
        async onDeleteResult(resultId) {
            await this.$confirm({
                message: `Are you sure you want delete this result?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        try {
                            await this.deleteResult(resultId);

                            this.$notify({
                                type: 'success',
                                text: 'Result deleted successfully'
                            });
                        } catch (e) {
                            this.$notify({
                                type: 'error',
                                text: _.get(e.response, 'data.message', null) || e.message || 'Error when deleting the result'
                            });
                        }
                    }
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.flex-container {
    flex-direction: 'row';

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
}
</style>
