import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.store';
import menstruation from './modules/menstruation.store';
import order from './modules/order.store';
import quiz from './modules/quiz.store';
import result from './modules/result.store';
import stripe from './modules/stripe.store';
import tenant from './modules/tenant.store';
import user from './modules/user.store';
import wearable from './modules/wearable.store';
import metrics from './modules/metrics.store';
import dashboard from './modules/dashboard.store';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {
        loading: {}
    },
    modules: {
        auth,
        menstruation,
        order,
        quiz,
        result,
        stripe,
        tenant,
        dashboard,
        user,
        wearable,
        metrics
    },
    mutations: {
        activateLoading(state, key) {
            Vue.set(state.loading, key, true);
        },
        deactivateLoading(state, key) {
            Vue.set(state.loading, key, false);
        }
    }
});
