<template>
    <div>
        <div v-can-access.role="'admin doctor'">
            <div class="row justify-content-md-center flex-column mx-0">
                <div class="form-group">
                    <h4 class="text__page-header mt-2">
                        {{ RESULTS_MANAGEMENT.headline }}

                        <small v-if="currentUser.useDoctorLanguage">
                            <br /><span class="text-muted text-sm small"
                                ><small
                                    >All the open results and your own finished results. You can have <b>maximum 5 results</b> assigned in one
                                    go</small
                                ></span
                            ></small
                        >
                    </h4>

                    <ResultsTable :results="resultsList" @pageChange="onPageChange" @search="onSearchTerm" />
                </div>
            </div>
        </div>
        <!-- <div v-else class="d-flex flex-column justify-content-center align-items-center" style="height: 82vh">
                <div>{{ RESULTS_MANAGEMENT.noResults }}</div>
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/svg/empty-state/empty-folder.svg" class="empty-state-image" />
                </div>
            </div> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ResultsTable from '@/components/results-table';
import { RESULTS_MANAGEMENT } from '@/utils/constants';

export default {
    components: {
        ResultsTable
    },
    data() {
        return {
            RESULTS_MANAGEMENT
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser',
            allOrders: 'order/allOrders',
            allResults: 'result/allResults',
            finished: 'result/finished',
            page: 'result/page',
            pageSize: 'result/pageSize',
            currentUser: 'user/currentUser'
        }),
        resultsList() {
            return _.values(this.allResults);
        }
    },
    async created() {
        if (this.user.isDoctor || this.user.isAdmin) {
            await this.getAllResults();
        }
    },
    methods: {
        ...mapActions({
            getAllResults: 'result/getAllResults'
        }),
        async onPageChange({ page, finishedStatus, queryString }) {
            await this.getAllResults({
                page,
                pageSize: this.pageSize,
                finished: finishedStatus,
                queryString
            });
        },
        async onSearchTerm(searchTerm) {
            await this.getAllResults({
                page: 1,
                pageSize: this.pageSize,
                queryString: searchTerm,
                finished: this.finished
            });
        }
    }
};
</script>
